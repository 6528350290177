import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disableAdultGoods, toggleAdultGoodsPopup } from '../../../state/modules/ui/actions';
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';
import { ADULT_GOODS_ALLOWED_COOKIE_NAME } from '../../../utils/constants';

export const AdultGoodBlur = ({ disableBlur = false }) => {
  const dispatch = useDispatch();
  const { isAdultGoodsBlocked } = useSelector(state => state.ui);


  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(toggleAdultGoodsPopup());
  };

  useEffect(() => {
    const isAlcoholAllowed = isomorphicCookies().get(ADULT_GOODS_ALLOWED_COOKIE_NAME);

    if (isAlcoholAllowed) {
      dispatch(disableAdultGoods());

    }
  }, []);

  if (!isAdultGoodsBlocked) {
    return null;
  }

  return (
    <div style={{
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backdropFilter: disableBlur ? 'none' : 'blur(5px)',
    }}
         onClick={handleClick} />

  );
};

