import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import qs from 'query-string';
import { CRUST_EXTRA, CRUST_EXTRA_EN } from '../../utils/constants';
// State
import { changeAvailableVariations } from '../../state/modules/constructor/actions';
import { removeCartItem, updateCartItem } from '../../state/modules/cart/actions';
import { unusedBonusesSum as unusedBonusesSumSelector } from '../../state/modules/user/selectors';

// Router
import { getRoute, paths } from '../../entry/routes';

// Components
import Button from '../Button';
import Amount from '../Amount';
import PizzaSelector from '../PizzaSelector';
import SizeSelector from '../SizeSelector';
import Modal from './Modal3';
import ProductCardModal from './ProductCardModal';
import Crust from './Crust';
import useGiftsSlider from '../GiftsSlider/useGiftsSlider';

// Partials
import Ingredients from './Ingredients';
import AlcoholWarning from './AlcoholWarning';
import Characteristics from './Characteristics';
import Flipper from './Flipper';
import MetaOption from './MetaOption';
import PromoArtefact from '../../scenes/Home/PromoGame/PromoArtefact';

// Icons
import GiftIcon from '../../icons/gift.svg';
import CheeseIcon from '../../icons/cheese.svg';

// Assets
import comet from '../../assets/Astronaut_images/comet.png';

// Utils
import {
  isAdult,
  isAlcohol,
  isHalal,
  isHit,
  isHot,
  isNew,
  isPizza,
  isSale,
  isVegan,
  isVegeterian,
} from '../../utils/catalog';
import {
  getDoughTypes,
  getPersonCount,
  getSizes,
  getStuffedCrustIsAvailable,
  getStuffedCrusts,
  getVariation,
} from '../../state/modules/catalog/utils';
import { sendEventAddProduct, sendEventViewProduct } from '../../utils/metrics';

// Styles
import styles from './ProductCard.styl';
import {
  isAstronautTheme,
  isMaterAndMargo,
  isThemeHalloween,
  isThemeNewYear,
  isMovieBorderTheme, isMoviePaddingtonTheme,
} from '../../state/modules/city/selectors';
import { setOpenedProductCard } from '../../state/modules/ui/actions';
import { removeNumbers } from '../../utils/validators';
import PromotionMovieBorderDecor from '../PromotionMovieBorder/PromotionMovieBorderDecor';
import PromotionMoviePaddingtonDecor from '../PromotionMoviePaddington/PromotionMoviePaddingtonDecor';
import { AdultGoodBlur } from './AdultGoodBlur/AdultGoodBlur';

const cx = classNames.bind(styles);

export const isTimeInterval = (enabledTimes, currentTime) => {
  let isInterval = false;
  if (enabledTimes.length > 0) {
    enabledTimes.forEach(item => {
      const timeStart =
        Number(item.time_start.slice(0, 2) * 60) + Number(item.time_start.slice(3, 5));
      const timeEnd = Number(item.time_end.slice(0, 2) * 60) + Number(item.time_end.slice(3, 5));
      if (currentTime >= timeStart - 0.5 && currentTime <= timeEnd + 0.5) {
        isInterval = true;
      }
    });
  } else {
    isInterval = true;
  }
  return isInterval;
};

function ProductCard(props) {
  const {
    className,
    stockRole,
    good,
    addToCart,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    // type может придти только из StockGoods - потому что там есть подарки
    type,
    // если мы открываем эту карту из ProductCardCompact
    compact,
    isFromProductList,
    sailplayGifts,
    hideModal,
    setIsOpenModalBonus,
    setIsOpenModalAuth,
    category: categoryName,
    isModalOpenPhone,
    isHiddenActions,
    isLoadingStockApply,
    promotionArtefactProperties,
    additionalBanner,
    showPromoArtefact,
    cardPositionInList,
  } = props;
  const { id, name, alias, data, types, show_ingredients_button, good_type, category, slug } = good;

  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const unusedBonusesSum = useSelector(unusedBonusesSumSelector);
  const sailplayType = useSelector(state => state.city?.userCity?.sailplay?.type);
  const isHalloweenMode = useSelector(isThemeHalloween);
  const isNewYearMode = useSelector(isThemeNewYear);
  const isMMGame = useSelector(isMaterAndMargo);
  const isAstronaut = useSelector(isAstronautTheme);
  const isMovieBorder = useSelector(isMovieBorderTheme);
  const isMoviePaddington = useSelector(isMoviePaddingtonTheme);
  const isPhone = useSelector(state => state.responsive.isPhone || false);
  const isDisabledLoyalty = useMemo(() => sailplayType !== 'none', [sailplayType]);
  const { name: nameDesc, declension } = useSelector(state => state.city.userCity);
  const vars = { declension, nameDesc };
  const [currentTime, setCurrentTime] = useState(
    new Date().getHours() * 60 + new Date().getMinutes(),
  );
  const [isLoadingPoints, setIsLoadingPoints] = useState(false);

  const { list, onAddClick } = useGiftsSlider();
  const addPointProductHandler = async () => {
    sendEventAddProduct();
    await setIsLoadingPoints(true);

    if (pointPrice) {
      if (!isAuthenticated) {
        setIsOpenModalAuth(true);
      }
      if (pointPrice > unusedBonusesSum && isAuthenticated) {
        setIsOpenModalBonus(true);
      }
      const gift = list.find(item => variation.id === item.item.id);

      if (isAuthenticated && pointPrice <= unusedBonusesSum) {
        await onAddClick(gift);
      }
      await setIsLoadingPoints(false);
      hideModal();
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date().getHours() * 60 + new Date().getMinutes());
    }, 1000 * 60);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isVariationInCurrentTime = good?.variations.filter(item => {
    const enabledTimes = item.enabled_times;
    return isTimeInterval(enabledTimes, currentTime);
  });

  const intl = useIntl();
  const history = useHistory();

  const categoriesAliases = useSelector(state => state.catalog.products.categoriesAliases);
  const browserName = useSelector(state => state.responsive.browserName);

  const timeout = useRef({});
  const [lastAddedCartItem, setLastAddedCartItem] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenUpsaleModal, setIsOpenUpsaleModal] = useState(false);
  const [isSelectUpsale, setIsSelectUpsale] = useState(false);
  const [selectedCrust, setSelectedCrust] = useState(null);

  // лист доступного теста
  const doughs = useMemo(() => {
    return getDoughTypes(good?.variations);
  }, [good?.variations]);

  const [dough, setDough] = useState(() => {
    return doughs[0]?.id;
  });

  // лист вариаций с выбранным тестом. если у товара нет теста, то просто лист вариаций
  const variationsByDough = useMemo(() => {
    return isPizza(good?.variations[0])
      ? good?.variations.filter(variant => variant.kind?.id === dough)
      : good?.variations;
  }, [good?.variations, dough]);

  // можно ли вызвать окно товара
  const isClickable = useMemo(() => {
    return isFromProductList && good_type !== 'combo';
  }, [isFromProductList, good_type]);

  // открыта/закрыта модалка
  const isOpen = useMemo(() => {
    return isClickable && isModalOpen;
  }, [isClickable, isModalOpen]);

  // лист размеров у выбранного теста
  const sizes = useMemo(() => {
    return getSizes(variationsByDough);
  }, [variationsByDough]);

  const [size, setSize] = useState(() => {
    return sizes[0]?.value;
  });

  // лист бортов для выбранного теста и размера
  const stuffedCrusts = useMemo(() => {
    return getStuffedCrusts(variationsByDough, size);
  }, [variationsByDough, size]);

  const [stuffedCrust, setStuffedCrust] = useState(() => {
    return stuffedCrusts.includes('none') ? 'none' : stuffedCrusts[0];
  });

  // вариация исходя из выбранного теста, размера, борта, корочки
  const variation = useMemo(() => {
    const newVariation = { ...getVariation(variationsByDough, size, stuffedCrust) };
    const isNewSelectedCrustAvailable = newVariation?.available_ingredients?.some(
      ingredient => ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN,
    );
    const newSelectedCrust = newVariation?.available_ingredients?.find(
      ingredient => ingredient.id === selectedCrust?.id,
    );
    if (selectedCrust && isNewSelectedCrustAvailable && newSelectedCrust) {
      newSelectedCrust.count = 1;
      newVariation.added_ingredients = [newSelectedCrust];
      newVariation.available_ingredients = newVariation.available_ingredients.map(ingredient =>
        ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN
          ? ingredient.id === selectedCrust.id
            ? { ...ingredient, count: 1 }
            : { ...ingredient, count: 0 }
          : ingredient,
      );
    }
    if (!selectedCrust && isNewSelectedCrustAvailable) {
      newVariation.added_ingredients = [];
      newVariation.available_ingredients = newVariation.available_ingredients.map(ingredient =>
        ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN
          ? {
              ...ingredient,
              count: 0,
            }
          : ingredient,
      );
    }
    good.variations = good?.variations.map(item =>
      item.id === newVariation.id ? { ...newVariation } : { ...item },
    );
    return newVariation;
  }, [variationsByDough, size, stuffedCrust, selectedCrust]);

  //индикатор для проверки является ли товар подарочным (для отрисовки кнопки купить за баллы)
  const isGift = sailplayGifts?.some(item => item.item.id === variation.id);

  const pointPrice = sailplayGifts?.find(item => item.item.id === variation.id)?.points;
  const resetCrust = useMemo(
    () => ({
      reset: () => {},
    }),
    [],
  );

  const setIsFlippedFalse = (withouReset = false) => {
    setIsFlipped(false);
    if (!withouReset) {
      // удаляем добавленную корочку
      resetCrust.reset();
      variation.added_ingredients = variation?.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN,
      );
    }
  };
  const {
    price,
    old_price,
    image_list,
    image_list_webp,
    image_cart,
    image_cart_webp,
    characteristics = [],
    include_ingredients,
    available_ingredients,
  } = variation;

  const options = useMemo(() => {
    // количество людей на одну пиццу
    const personCount = getPersonCount(variation);

    return [
      isHot(types) && <MetaOption className={cx(`ProductCard__option`)} type="hot" />,
      isHalal(types) && <MetaOption className={cx(`ProductCard__option`)} type="halal" />,
      isVegeterian(types) && <MetaOption className={cx(`ProductCard__option`)} type="vegetarian" />,
      personCount && (
        <MetaOption className={cx(`ProductCard__option`)} type="person-count">
          {personCount}
        </MetaOption>
      ),
    ].filter(Boolean);
  }, [types, variation]);

  const meta = useMemo(() => {
    const result = [
      isVegan(types) && (
        <span className={cx(`ProductCard__badge`, 'ProductCard__badge_vegan')}>
          {intl.formatMessage({ id: 'vegan' })}
        </span>
      ),
      !isVegan(types) && isSale(types) && (
        <span className={cx(`ProductCard__badge`, 'ProductCard__badge_sale')}>Sale</span>
      ),
      !isVegan(types) && !isSale(types) && isNew(types) && (
        <span className={cx(`ProductCard__badge`)}>New</span>
      ),
      !isVegan(types) && !isNew(types) && !isSale(types) && isHit(types) && (
        <span className={cx(`ProductCard__badge`)}>Hit</span>
      ),
      ...options,
    ].filter(Boolean);

    return result.length > 0 ? <div className={cx('ProductCard__meta')}>{result}</div> : null;
  }, [options, types]);

  const getSizeSelector = useCallback(
    params => {
      // доступен ли какой-то борт кроме стандартного
      const stuffedCrustIsAvailable = getStuffedCrustIsAvailable(variationsByDough, size);

      const changeDough = newDough => {
        setDough(newDough);
      };

      const changeSize = newSize => {
        setSize(newSize);
      };

      const selectStuffedCrust = newStuffedCrust => {
        setStuffedCrust(newStuffedCrust);
      };

      return isPizza(good?.variations[0]) ? (
        <PizzaSelector
          className={params.className}
          sizes={sizes}
          currentSize={size}
          doughTypes={doughs}
          currentDough={dough}
          stuffedCrustIsAvailable={stuffedCrustIsAvailable}
          stuffedCrustsAvailable={stuffedCrusts}
          activeStuffedCrust={stuffedCrust}
          changeSize={changeSize}
          changeDough={changeDough}
          selectStuffedCrust={selectStuffedCrust}
          toggleStuffedCrust={selectStuffedCrust}
          isBottom={params.isBottom}
          specialStylesMode={params.specialStylesMode}
        />
      ) : (
        <SizeSelector
          className={params.className}
          sizes={sizes}
          currentSize={size}
          onChange={changeSize}
          specialStylesMode={params.specialStylesMode}
        />
      );
    },
    [variationsByDough, sizes, size, doughs, dough, stuffedCrusts, stuffedCrust],
  );

  const backside = useMemo(() => {
    // на задней стороне карты удаляем уже добавленный товар и добавляем его с сырным бортом
    const addToCartFromBackside = async () => {
      // переворачиваем карту
      setIsFlippedFalse(true);
      // если больше одного товара, то делаем -1
      if (lastAddedCartItem.count > 1) {
        await dispatch(
          updateCartItem({ ...lastAddedCartItem, count: lastAddedCartItem.count - 1 }),
        );
      } else {
        await dispatch(removeCartItem(lastAddedCartItem.hash));
      }

      // и добавляем вместо него новый с сырным бортом
      const cheeseVariation = { ...getVariation(variationsByDough, size, 'cheese') };
      // переносим ингредиенты в новую вариацию из текущей
      cheeseVariation.include_ingredients = variation.include_ingredients;
      // проверяем доступна ли корочка в новой вариации
      const isSelectedCrustAvailable = cheeseVariation?.available_ingredients?.some(
        ingr => ingr.id === selectedCrust?.id,
      );
      if (selectedCrust && isSelectedCrustAvailable) {
        selectedCrust.count = 1;
        cheeseVariation.added_ingredients = [selectedCrust];
      }
      setIsAdding(true);
      await addToCart(cheeseVariation);
      setIsAdding(false);
      // удаляем добавленную корочку
      resetCrust.reset();
      variation.added_ingredients = variation?.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN,
      );
    };

    // если это не мобильная версия, не меню выбора акционного товара, не сам акционный товар и у него есть сырный борт
    if (!compact && !stockRole && !variation.old_price && stuffedCrusts.includes('cheese')) {
      return (
        <div className={cx('ProductCard__backside')}>
          <span className={cx('ProductCard__backside-description')}>
            {intl.formatMessage({ id: 'productcard.cheesecrust' })}
          </span>
          <CheeseIcon className={cx('ProductCard__backside-icon')} />
          <div className={cx('ProductCard__backside-buttons')} data-test-id="cheesecrust_buttons">
            <Button
              onClick={addToCartFromBackside}
              disabled={isAdding}
              className={cx('ProductCard__backside-buttons-yes')}
            >
              {intl.formatMessage({ id: 'question.yes' })}
            </Button>
            <Button
              className={cx('ProductCard__backside-buttons-no')}
              secondary
              disabled={isAdding}
              onClick={() => setIsFlippedFalse()}
            >
              {intl.formatMessage({ id: 'question.no' })}
            </Button>
          </div>
        </div>
      );
    }
    if (compact && !stockRole && !variation.old_price && stuffedCrusts.includes('cheese')) {
      return (
        <Modal
          isOpen={isOpenUpsaleModal}
          onClose={() => {
            setIsOpenUpsaleModal(false);
            setIsFlippedFalse(true);
          }}
        >
          <div className={cx('ProductCard__backside', 'ProductCard__backside_adaptive')}>
            <span
              className={cx(
                'ProductCard__backside-description',
                'ProductCard__backside-description_adaptive',
              )}
            >
              {intl.formatMessage({ id: 'productcard.cheesecrust' })}
            </span>
            <CheeseIcon
              className={cx('ProductCard__backside-icon', 'ProductCard__backside-icon_adaptive')}
            />
            <div
              className={cx(
                'ProductCard__backside-buttons',
                'ProductCard__backside-buttons_adaptive',
              )}
            >
              <Button
                onClick={async () => {
                  setStuffedCrust('cheese');
                  setIsOpenUpsaleModal(false);
                  setIsSelectUpsale(true);
                  let newVariation = { ...getVariation(variationsByDough, size, 'cheese') };
                  const isSelectedCrustAvailable = newVariation?.available_ingredients?.some(
                    ingr => ingr.id === selectedCrust?.id,
                  );
                  if (selectedCrust && isSelectedCrustAvailable) {
                    selectedCrust.count = 1;
                    newVariation.added_ingredients = [selectedCrust];
                  }

                  await addToCart(newVariation);
                  setIsFlippedFalse(false);
                }}
                disabled={isAdding}
                className={cx(
                  'ProductCard__backside-buttons-yes',
                  'ProductCard__backside-buttons-yes_adaptive',
                )}
              >
                {intl.formatMessage({ id: 'question.yes' })}
              </Button>
              <Button
                className={cx(
                  'ProductCard__backside-buttons-no',
                  'ProductCard__backside-buttons-no_adaptive',
                )}
                secondary
                disabled={isAdding}
                onClick={async () => {
                  setIsSelectUpsale(true);
                  setIsOpenUpsaleModal(false);
                  await addToCart(variation);
                  setIsFlippedFalse();
                }}
              >
                {intl.formatMessage({ id: 'question.no' })}
              </Button>
            </div>
          </div>
        </Modal>
      );
    }

    return null;
  }, [
    browserName,
    compact,
    variation,
    stuffedCrusts,
    stockRole,
    isAdding,
    lastAddedCartItem,
    variationsByDough,
    size,
    id,
    addToCart,
    isOpenUpsaleModal,
    selectedCrust,
  ]);

  const onPushToConstructor = useCallback(() => {
    sendEventViewProduct();
    dispatch(changeAvailableVariations(id, good?.variations));

    const queryParams = qs.stringify({
      dough,
      size,
      stuffedCrust,
      fromProduct: 1,
      removedIngredientsIds: include_ingredients
        ?.filter(ingredient => ingredient.removed)
        .map(ingredient => ingredient.id),
    });

    if (id === 15) {
      history.push({ pathname: getRoute(paths.constructorAliasNotId), search: queryParams });
    } else {
      history.push({ pathname: getRoute(paths.constructorAlias, id), search: queryParams });
    }
  }, [
    id,
    good?.variations,
    dough,
    size,
    stuffedCrust,
    include_ingredients,
    history,
    changeAvailableVariations,
    sendEventViewProduct,
  ]);

  const onAddToCart = async () => {
    sendEventAddProduct();
    const onSuccessCallback = params => {
      // если доступен сырный борт и если он еще не выбран
      if (Boolean(backside) && stuffedCrust === 'none') {
        setLastAddedCartItem(
          params.composition.find(cartItem => cartItem.item.id === variation.id),
        );
        // то переворачиваем карту товара
        setIsFlipped(true);
        timeout.current = setTimeout(() => {
          setIsFlippedFalse();
        }, 8000);
      } else {
        // удаляем добавленную корочку
        resetCrust.reset();
        delete variation?.added_ingredients;
      }
    };

    if (compact && stuffedCrust === 'none' && !isSelectUpsale && stuffedCrusts.includes('cheese')) {
      setIsOpenUpsaleModal(true);
    } else {
      setIsAdding(true);
      await addToCart(variation, onSuccessCallback);
      setIsAdding(false);
      // сброс ингридиентов
      variation.added_ingredients = variation.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN,
      );
      return resetCrust.reset();
    }

    if (compact && stuffedCrusts.length === 1 && stuffedCrusts.includes('none') && !backside) {
      setIsAdding(true);
      await addToCart(variation, onSuccessCallback);
      setIsAdding(false);
      // сброс ингридиентов
      variation.added_ingredients = variation.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN,
      );
      return resetCrust.reset();
    }
  };

  // комбобоксы не сочетаются с промокодами, поэтому при добавлении надо спросить
  const goToComboboxPage = () => {
    sendEventViewProduct();
    history.push(getRoute(paths.comboboxAlias, removeNumbers(alias)));
  };

  const onRemoveIngredient = useCallback(
    ingredientId => {
      sendEventViewProduct();
      removeIngredientAction({ id, variation, ingredientId });
    },
    [id, variation, sendEventViewProduct],
  );

  const onCancelRemovedIngredients = useCallback(() => {
    sendEventViewProduct();
    cancelRemovedIngredientsAction({ id, variation });
  }, [id, variation, sendEventViewProduct]);
  const { category: categoryUrl, slug: slugUrl } = useParams();

  const onModalOpen = useCallback(() => {
    sendEventViewProduct();
    setIsModalOpen(true);
    // костыль изза поломанного роутинга
    dispatch(setOpenedProductCard(id));
    if (categoryUrl !== 'combo-box') {
      window.history.pushState({}, '', getRoute(paths.productAlias, category, slug?.toLowerCase()));
    }
  }, [sendEventViewProduct, category, slug]);

  const onModalClose = useCallback(() => {
    sendEventViewProduct();
    setIsModalOpen(false);
    // костыль изза поломанного роутинга
    dispatch(setOpenedProductCard(null));
    window.history.pushState({}, '', getRoute(paths.home));
  }, [sendEventViewProduct]);

  const handleUrl = () => {
    setIsModalOpen(slugUrl === slug?.toLowerCase());
  };

  useEffect(() => {
    handleUrl();
  }, []);

  useEffect(() => {
    if (!isFlipped && timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [isFlipped]);

  useEffect(() => {
    // Создаем элемент <link> для canonical URL
    const canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', window.location.href); // Получаем текущий URL

    if (isOpen) {
      document.title = intl.formatMessage(
        { id: 'page.menu.helmet.product.modal' },
        {
          category: categoryName,
          name,
          declension,
        },
      );
      document.querySelector('meta[name="description"]').setAttribute('content', data);
    } else {
      document.title = intl.formatMessage({ id: 'page.menu.helmet' }, { declension });
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', intl.formatMessage({ id: 'page.menu.helmet.description' }, vars));
    }

    // Удаление старого canonical элемента, если есть
    const oldCanonicalLink = document.querySelector('link[rel="canonical"]');
    if (oldCanonicalLink) {
      document.head.removeChild(oldCanonicalLink);
    }

    // Добавляем новый canonical элемент в <head>
    document.head.appendChild(canonicalLink);
  }, [isOpen, isPhone]);

  useEffect(() => {
    // Создаем элемент <link> для canonical URL
    const canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', window.location.href); // Получаем текущий URL
    if (isPhone && isModalOpenPhone)
      document.title = intl.formatMessage(
        { id: 'page.menu.helmet.product.modal' },
        {
          category: categoryName,
          name,
          declension,
        },
      );
    else {
      document.title = intl.formatMessage({ id: 'page.menu.helmet' }, { declension });
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', intl.formatMessage({ id: 'page.menu.helmet.description' }, vars));
    }
    // Удаление старого canonical элемента, если есть
    const oldCanonicalLink = document.querySelector('link[rel="canonical"]');
    if (oldCanonicalLink) {
      document.head.removeChild(oldCanonicalLink);
    }

    // Добавляем новый canonical элемент в <head>
    document.head.appendChild(canonicalLink);
  }, [good, isPhone, isModalOpenPhone]);

  let goodPrice = price;
  let goodOldPrice = old_price;
  const isCrustAvailable = available_ingredients?.some(ingr => ingr.id === selectedCrust?.id);
  if (selectedCrust && isCrustAvailable) {
    goodPrice += +selectedCrust.price;
    goodOldPrice += +selectedCrust.price;
  }

  const productCardCN = cx('ProductCard', className, {
    ProductCard__hidden: isVariationInCurrentTime.length === 0,
    ProductCard__halloween: isHalloweenMode,
    'ProductCard__new-year': isNewYearMode,
  });

  return (
    <Flipper className={productCardCN} backside={backside} isFlipped={isFlipped}>
      {additionalBanner}
      <picture
        className={cx('ProductCard__picture', {
          ProductCard__picture_clickable: isClickable && !isHiddenActions,
        })}
        onClick={isClickable && !isHiddenActions && onModalOpen}
      >
        <source srcSet={compact ? image_cart_webp : image_list_webp} type="image/webp" />
        {compact ? (
          <img
            className={cx('ProductCard__image')}
            alt={`${categoriesAliases[category]} ${name}`}
            src={image_cart}
          />
        ) : (
          <LazyLoadImage
            className={cx('ProductCard__image')}
            alt={`${categoriesAliases[category]} ${name}`}
            src={image_list}
            threshold={300}
          />
        )}
        {isAdult(types) && <AdultGoodBlur />}
      </picture>
      <div style={{ position: 'relative' }}>
        {isMovieBorder && cardPositionInList === 3 && isFromProductList && (
          <PromotionMovieBorderDecor srcView={'3'} className={cx(`ProductCard__PromoBorder_pic`)} />
        )}
      </div>
      {isMoviePaddington && cardPositionInList === 0 && isFromProductList && (
        <PromotionMoviePaddingtonDecor srcView={'3'} className={cx(`ProductCard__PaddingtonDecor`)} />
      )}
      <div className={cx('ProductCard__container')}>
        {showPromoArtefact && promotionArtefactProperties?.positionVariant !== 'button' && (
          // activeEffect будет работать для 1апреля инче будет переопределен внутри
          <PromoArtefact
            activeEffect={'toLeft'}
            id={id}
            className={cx(`ProductCard__promoGame_${promotionArtefactProperties?.positionVariant}`)}
          />
        )}
        {meta || <div style={{ height: '32px' }} />}
        <div
          className={cx(`ProductCard__header`)}
          style={data ? null : { marginBottom: '24px' }}
          data-test-id="product_card_header"
        >
          {/* Название товара */}
          <span className={cx(`ProductCard__heading`)}>{name}</span>
          <Characteristics characteristics={characteristics} specialStylesMode />

          {/* Тултип с характеристиками */}
        </div>
        {/* Описание товара */}
        {data && (
          <div className={cx(`ProductCard__subheading`)}>
            {data}
            <div>
              {stuffedCrust === 'cheese' &&
                intl.formatMessage({ id: 'pizza.stuffedCrust.cheese.description' })}
              {stuffedCrust === 'sausage' &&
                intl.formatMessage({ id: 'pizza.stuffedCrust.sausage.description' })}
            </div>
          </div>
        )}
        {!isHiddenActions && (
          <div
            className={cx('ProductCard__actions', {
              ProductCard__actions_compact: compact,
            })}
          >
            {getSizeSelector({
              className: cx('ProductCard__selector'),
              specialStylesMode: true,
            })}
            {isPizza(good?.variations[0]) ? (
              <Crust
                variation={variation}
                remove={onRemoveIngredient}
                good={good}
                resetCrust={resetCrust}
                selectedCrust={selectedCrust}
                setSelectedCrust={setSelectedCrust}
                specialStylesMode
              />
            ) : null}
            {/* В корзину */}
            <div
              className={cx('ProductCard__button-wrapper', {
                'ProductCard__button-wrapper_compact': compact,
              })}
            >
              {showPromoArtefact && promotionArtefactProperties?.positionVariant === 'button' && (
                <PromoArtefact
                  activeEffect={isMMGame ? 'opacity' : 'toTop'}
                  id={id}
                  imageProps={promotionArtefactProperties}
                  className={cx('ProductCard__promoGame_button', {
                    ProductCard__promoGame_button_mm: isMMGame,
                  })}
                />
              )}
              <div style={{ position: 'relative' }}>
                <Button
                  className={cx('ProductCard__button')}
                  onClick={good_type === 'combo' ? goToComboboxPage : onAddToCart}
                  isLoading={isAdding || isLoadingStockApply}
                  id={'btn_add_cart'}
                >
                  {good_type === 'combo'
                    ? intl.formatMessage({ id: 'combobox.card.button' })
                    : intl.formatMessage({ id: type === 'gift' ? 'button.get' : 'button.inCart' })}
                </Button>
                {isMovieBorder && cardPositionInList === 8 && isFromProductList && (
                  <PromotionMovieBorderDecor
                    srcView={'8'}
                    className={cx(`ProductCard__PromoBorder_button`)}
                  />
                )}
              </div>

              {/* Покупка за баллы */}
              {isDisabledLoyalty && isGift && (
                <button
                  className={cx('ProductCard__points', {
                    ProductCard__points_disabled: pointPrice > unusedBonusesSum,
                    // 'ProductCard__points_halloween': isHalloweenMode,
                    // 'ProductCard__points_new-year': isNewYearMode,
                  })}
                  onClick={addPointProductHandler}
                >
                  {!isLoadingPoints ? (
                    `${pointPrice} ${intl.formatMessage({ id: 'bonus' })}`
                  ) : (
                    <span className={cx('ProductCard__spinner')} />
                  )}
                </button>
              )}
              {/* Цена */}
              {type === 'gift' ? (
                <GiftIcon
                  className={cx('ProductCard__price', 'ProductCard__gift', {
                    ProductCard__price_compact: compact,
                  })}
                />
              ) : (
                <Amount
                  className={cx('ProductCard__price', { ProductCard__price_compact: compact })}
                  value={goodOldPrice || goodPrice}
                  newValue={goodPrice}
                  specialStylesMode
                />
              )}
              {isAstronaut && (
                <img
                  src={comet}
                  width={40}
                  alt={'img'}
                  className={cx('ProductCard__cosmo_decor')}
                />
              )}
            </div>
          </div>
        )}
        {isAlcohol(types) && <AlcoholWarning />}
        {isAdult(types) && <AdultGoodBlur disableBlur />}
        {Boolean(show_ingredients_button) &&
          !isHiddenActions &&
          (!_isEmpty(include_ingredients) || !_isEmpty(available_ingredients)) && (
            <Ingredients
              isAdding={isAdding}
              variation={variation}
              stockRole={stockRole}
              pushToConstructor={onPushToConstructor}
              addToCart={onAddToCart}
              remove={onRemoveIngredient}
              cancel={onCancelRemovedIngredients}
              specialStylesMode
            />
          )}
        {isOpen && (
          <ProductCardModal
            isOpen={isOpen}
            good={good}
            variations={good?.variations}
            variation={variation}
            options={options}
            getSizeSelector={getSizeSelector}
            pushToConstructor={onPushToConstructor}
            modalClose={onModalClose}
            removeIngredient={onRemoveIngredient}
            addToCart={onAddToCart}
            remove={onRemoveIngredient}
            resetCrust={resetCrust}
            selectedCrust={selectedCrust}
            setSelectedCrust={setSelectedCrust}
            value={goodOldPrice || goodPrice}
            newValue={goodPrice}
          />
        )}
      </div>
    </Flipper>
  );
}

ProductCard.defaultProps = {
  className: '',
  stockRole: '',
  type: 'good',
  compact: false,
  isFromProductList: false,
  category: '',
  isHiddenActions: false,
  additionalBanner: null,
  showPromoArtefact: true,
};

ProductCard.propTypes = {
  className: PropTypes.string,
  stockRole: PropTypes.string,
  good: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeIngredientAction: PropTypes.func.isRequired,
  cancelRemovedIngredientsAction: PropTypes.func.isRequired,
  type: PropTypes.string,
  compact: PropTypes.bool,
  isFromProductList: PropTypes.bool,
  hideModal: PropTypes.func,
  category: PropTypes.string,
  isHiddenActions: PropTypes.bool,
  promotionArtefactProperties: PropTypes.object,
  additionalBanner: PropTypes.element,
  showPromoArtefact: PropTypes.bool,
  cardPositionInList: PropTypes.number.isRequired,
};

export default memo(ProductCard);
