import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';

// Components
import { noop } from 'lodash';
import Heading from '../../Heading';
import Button from '../../Button';
import Modal from '../../Modal';
import Crust from '../Crust';

// Utils
import { isHit, isNew, isPizza } from '../../../utils/catalog';

// Styles
import styles from './ProductCardModal.styl';

const cx = classNames.bind(styles);

export default function ProductCardModal(props) {
  const {
    className,
    isOpen,
    good,
    variation,
    options,
    getSizeSelector,
    pushToConstructor,
    modalClose,
    addToCart,
    removeIngredient,
    onRemoveIngredient,
    resetCrust,
    selectedCrust,
    setSelectedCrust,
    value,
    newValue,
    variations,
  } = props;
  const { name, data, types, category } = good;
  const {
    image_cart,
    image_cart_webp,
    characteristics = [],
    include_ingredients,
    available_ingredients,
  } = variation;
  const isNewValueExist = newValue !== value && (Boolean(newValue) || newValue === 0);
  const currentPrice = isNewValueExist ? newValue : value;
  const intl = useIntl();
  const categoriesAliases = useSelector(state => state.catalog.products.categoriesAliases);
  const currencyUnit = useSelector(state => state.city.userCity.currency?.unit);
  const [isAdding, setIsAdding] = useState(false);

  const onModalClose = useCallback(() => {
    modalClose();
  }, [modalClose]);

  const onAddToCart = useCallback(async () => {
    setIsAdding(true);
    await addToCart();
    onModalClose();
    setIsAdding(false);
  }, [addToCart, onModalClose]);

  const onPushToConstructor = useCallback(() => {
    pushToConstructor();
  }, [pushToConstructor]);
  return (
    <Modal className={cx('ProductCardModal', className)} isOpen={isOpen} onClose={onModalClose}>
      <div className={cx('ProductCardModal__left')}>
        <picture className={cx('ProductCardModal__top')}>
          {isNew(types) && <div className={cx('ProductCardModal__badge')}>New</div>}
          {!isNew(types) && isHit(types) && (
            <div className={cx('ProductCardModal__badge')}>Hit</div>
          )}
          <div className={cx('ProductCardModal__options')}>{options}</div>
          <source srcSet={image_cart_webp} type="image/webp" />
          <img
            className={cx('ProductCardModal__image')}
            alt={`${categoriesAliases[category]} ${name}`}
            src={image_cart}
          />
        </picture>
        {characteristics.length > 0 && (
          <div className={cx('ProductCardModal__bottom')}>
            <div className={cx('ProductCardModal__100g')}>
              {intl.formatMessage({ id: 'characteristics.tooltip.title' })}:
            </div>
            {characteristics.map(characteristic => (
              <p key={characteristic.code} className={cx('ProductCardModal__p')}>
                {characteristic.name}&nbsp;&mdash;{' '}
                <span className={cx('ProductCardModal__p_bold')}>{characteristic.value}</span>
              </p>
            ))}
          </div>
        )}
      </div>
      <div className={cx('ProductCardModal__right')}>
        <div className={cx('ProductCardModal__section')}>
          <Heading className={cx('ProductCardModal__heading')} level={2} medium>
            {name}
          </Heading>
          {data && <div className={cx('ProductCardModal__subheading')}>{data}</div>}
          {getSizeSelector({ className: cx('ProductCardModal__selector'), isBottom: true })}
          {!_isEmpty(available_ingredients) && (
            <Button className={cx('ProductCardModal__add')} onClick={onPushToConstructor} link>
              <div className={cx('ProductCardModal__add-icon')} />
              {intl.formatMessage({ id: 'button.addIngredients' })}
            </Button>
          )}
          {isPizza(variations[0]) ? (
            <Crust
              isOpen={isOpen}
              variation={variation}
              remove={onRemoveIngredient}
              good={good}
              resetCrust={resetCrust}
              selectedCrust={selectedCrust}
              setSelectedCrust={setSelectedCrust}
            />
          ) : null}

          {!_isEmpty(include_ingredients) && (
            <>
              <div className={cx('ProductCardModal__removed-heading')}>
                {intl.formatMessage({ id: 'productCard.removeIngredients.title' })}
              </div>
              <div className={cx('ProductCardModal__removed')}>
                {include_ingredients.map(ingredient => {
                  const isUndeleted = ingredient?.not_for_delete;
                  return (
                    <button
                      key={ingredient.id}
                      className={cx('ProductCardModal__removed-ingredient', {
                        'ProductCardModal__removed-ingredient_removed': ingredient.removed,
                        'ProductCardModal__removed-ingredient_required': isUndeleted,
                      })}
                      onClick={() => {
                        isUndeleted ? noop() : removeIngredient(ingredient.id);
                      }}
                      type="button"
                    >
                      <div className={cx('ProductCardModal__removed-img')}>
                        <img src={ingredient.image} alt={ingredient.name} />
                      </div>
                      <div className={cx('ProductCardModal__removed-name')}>{ingredient.name}</div>
                    </button>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div className={cx('ProductCardModal__footer')}>
          <Button
            className={cx('ProductCardModal__button')}
            onClick={onAddToCart}
            isLoading={isAdding}
          >
            {intl.formatMessage(
              { id: 'button.buyAmount' },
              {
                currency: currencyUnit,
                amount: currentPrice,
                old: isNewValueExist ? (
                  <span
                    style={{
                      textDecoration: 'line-through',
                      paddingInline: 5,
                    }}
                  >
                    {value.split('.')[0]} {currencyUnit}
                  </span>
                ) : null,
              },
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ProductCardModal.defaultProps = {
  className: '',
};

ProductCardModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  good: PropTypes.object.isRequired,
  variation: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  getSizeSelector: PropTypes.func.isRequired,
  pushToConstructor: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  resetCrust: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, undefined]),
};
